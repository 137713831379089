/* eslint-disable no-unused-vars */
import Swiper, { Navigation, Pagination, Thumbs, EffectFade } from 'swiper';
import { slideToggle } from './lib';

// モジュールを使用可能に
Swiper.use([Navigation, Pagination, Thumbs, EffectFade]);

const showPageTopBtn = () => {
  const trigger = document.getElementById('js-scroll-btn-trigger');
  const btn = document.getElementById('page-top');

  if (!trigger || !btn) return;

  const observer = new IntersectionObserver((entries) => {
    for (const e of entries) {
      if (e.isIntersecting) {
        btn.classList.remove('is-show');
      } else {
        btn.classList.add('is-show');
      }
    }
  }, {});
  observer.observe(trigger);
}
showPageTopBtn();

const hideContactBtn = () => {
  const trigger = document.querySelector('.globalfooter');
  const btn = document.querySelector('.js-list-submit-btn');

  if (!trigger || !btn) return;

  const observer = new IntersectionObserver((entries) => {
    for (const e of entries) {
      if (e.isIntersecting) {
        btn.classList.add('hide-btn');
      } else {
        btn.classList.remove('hide-btn');
      }
    }
  }, {});
  observer.observe(trigger);
}
hideContactBtn();

const submitContactCarAll = () => {

	const btns = document.querySelectorAll('.js-list-submit-btn');

	if(btns.length) {
		btns.forEach(function(btn) {
			btn.addEventListener('click', function() {

				const checkboxChecked = document.querySelectorAll('.item_large :checked');
				let idsList = [];
				if(checkboxChecked) {

					idsList = [...checkboxChecked].map(function(el) {
						return 'id[]=' + el.value;
					})
				}
				if(idsList.length == 0) {
					const url = '/page/contact';
					window.open(url, '_blank');
				} else {
					const url = '/car/contact' + '?' + idsList.join('&');
					window.open(url, '_blank');
				}
			})
		})
	}
}
submitContactCarAll();

const showDetailHeader = () => {
  const trigger = document.getElementById('js-detail-header-trigger');
  const nav = document.querySelector('.summarynavigation');
  const header = document.querySelector('.header-wrapper');

  if (!trigger || !nav) return;

  const observer = new IntersectionObserver((entries) => {
    for (const e of entries) {
      if (e.isIntersecting) {
        nav.classList.remove('show');
        header.classList.remove('hide');
      } else {
        nav.classList.add('show');
        header.classList.add('hide');
      }
    }
  }, {});
  observer.observe(trigger);
}
showDetailHeader();

const swiper = new Swiper('.sliderlist_eyecatch', {
  slidesPerView: 1,
  loop: true,
  spaceBetween: 12,
  initialSlide: 1,
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.eyecatch-next',
    prevEl: '.eyecatch-prev',
  },
  breakpoints: {
    769: {
      initialSlide: 0,
      enabled: false,
    }
  }
});

const swiperPickup = new Swiper('.pick-up-slider', {
  loop: false,
  slidesPerView: "auto",
  spaceBetween: 1,
  centeredSlides: false,
  initialSlide: 0,
  breakpoints: {
    769: {
      centeredSlides: false,
      enabled: true,
      loop: false,
      slidesPerView: 4,
    },
    1000: {
      centeredSlides: false,
      enabled: true,
      loop: false,
      slidesPerView: 5,
    },
  },
  navigation: {
    nextEl: '.pick-up-slider-next',
    prevEl: '.pick-up-slider-prev',
  },
});

const swiperRanking = new Swiper('.itemlist-ranking', {
  slidesPerView: 1.3,
  spaceBetween: 10,
  breakpoints: {
    500: {
      slidesPerView: 2.3,
      enabled: true,
    },
    769: {
      slidesPerView: "auto",
      spaceBetween: 20,
      enabled: false,
    }
  }
});

const swiperDetailBottom = new Swiper('.detail-bottom-slider', {
  spaceBetween: 1,
  slidesPerView: 6,
  freeMode: true,
  watchSlidesProgress: true,
  breakpoints: {
    769: {
      slidesPerView: "auto",
    }
  }
});

const swiperDetailTop = new Swiper('.detail-top-slider', {
  effect: "fade",
  thumbs: {
    swiper: swiperDetailBottom,
  },
  navigation: {
    nextEl: '.detail-slider-next',
    prevEl: '.detail-slider-prev',
  },

});

const changeTopSlide = () => {
  const slides = document.getElementsByClassName('imagelist-item-small');

  if (!slides || slides.length === 0) return;

  [...slides].forEach((elem, index) => {
    elem.dataset.index = index;
    elem.addEventListener('mouseenter', (e) => {
      const indexNumber = e.target.dataset.index;
      swiperDetailTop.slideTo(indexNumber);
    })
  })
}
changeTopSlide();
//hamburger
const openGlobalNav = () => {

  // const hamburgerOpen = document.getElementById('js-hamburger-open');
  const hamburgerOpen = document.getElementsByClassName('js-hamburger-open');

  if (!hamburgerOpen || hamburgerOpen.length === 0) return;

  const body = document.body;

  [...hamburgerOpen].forEach(elem => {
    elem.addEventListener('click', () => {
      body.classList.add('is-gnav-active');
    })
  });
}
openGlobalNav();

const closeGlobalNav = () => {

  const closeBtn = document.getElementsByClassName('js-hamburger-close');

  if (!closeBtn || closeBtn.length === 0) return;

  const hamburgerOutsideClose = document.getElementById('globalnav_bg');

  if (!hamburgerOutsideClose) return;

  const body = document.body;

  [...closeBtn].forEach(elem => {
    elem.addEventListener('click', () => {
      body.classList.remove('is-gnav-active');
    })

    hamburgerOutsideClose.addEventListener('click', () => {
      body.classList.remove('is-gnav-active');
    })
  })
}
closeGlobalNav();

//q&a アコーディオン
const faqAccodion = () => {
  const questions = document.querySelectorAll('.faq-block');

  if (!questions) return;

  [...questions].forEach((question) => {
    question.addEventListener('click', (e) => {
      slideToggle(question.querySelector('.answer'), 300);
    })
  })
}
faqAccodion();


const toggleDisplayNum = () => {
  const trigger = document.querySelector('.header-control-number-title');
  const numberOptions = document.querySelector('.header-control-number-list');

  if(!trigger || !numberOptions) return;

  trigger.addEventListener('click', () => {
    slideToggle(numberOptions, 300);
  })
}
toggleDisplayNum();

const carlistCheck = () => {
  let carlistCheck = document.getElementsByClassName('carlist__check');
  const body = document.body;

  if (!carlistCheck) return;

  [...carlistCheck].forEach(elem => {
    elem.addEventListener('change', () => {
      // 再取得
      carlistCheck = document.getElementsByClassName('carlist__check');

      let checked = false;
      const btn = document.getElementsByClassName('js-list-submit-btn');

      if (!btn) return;

      [...carlistCheck].forEach(e => {

        if (e.checked) {
          checked = true;
        }
      })

      if (checked) {
        [...btn].forEach(btnItem => {
          btnItem.classList.add('display');
          body.classList.add('has-contact-btn');
        });

      } else {
        [...btn].forEach(btnItem => {
          btnItem.classList.remove('display');
          body.classList.remove('has-contact-btn');
        });
      }

      checked = false;
    })
  })
}
carlistCheck();

const changeFontSize = () => {

  String.prototype.bytes = function () {
    let length = 0;
    for (let i = 0; i < this.length; i++) {
      const c = this.charCodeAt(i);
      if ((c >= 0x0 && c < 0x81) || (c === 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
        length += 1;
      } else {
        length += 2;
      }
    }
    return length;
  };

  const targets = document.getElementsByClassName('item-spec_item-change-font-size');

  if(!targets || targets.length === 0) return;

  [...targets].forEach(e => {

    const text = e.innerText;

    if(text.bytes() > 8) {
      e.style.fontSize = '10px';
    }
  })
}
changeFontSize();

const truncateText = (str, len) => {
  let result = 0;
  let text = '';
  for (let i = 0; i < str.length; i++) {
      let chr = str.charCodeAt(i);
      if (
          (chr >= 0x00 && chr < 0x81) ||
          chr === 0xf8f0 ||
          (chr >= 0xff61 && chr < 0xffa0) ||
          (chr >= 0xf8f1 && chr < 0xf8f4)
      ) {
          //半角文字の場合は1を加算
          result += 1;
      } else {
          //それ以外の文字の場合は2を加算
          result += 2;
      }
      if (result <= len) {
          text += str[i];
      } else {
          text += '...';
          break;
      }
  }
  return text;
}

const changeTextLength = () => {
  
  const comment = document.querySelectorAll('.review_item-text-bottom .review_item-comment');

  if(!comment || comment.length === 0) return;

  String.prototype.bytes = function () {
    let length = 0;
    for (let i = 0; i < this.length; i++) {
      const c = this.charCodeAt(i);
      if ((c >= 0x0 && c < 0x81) || (c === 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
        length += 1;
      } else {
        length += 2;
      }
    }
    return length;
  };

  comment.forEach(elem => {
    const text = elem.innerHTML;

    if(text.bytes() > 36) {
      const modStr = truncateText(text, 72);

      elem.innerHTML = modStr;
    }
  })
}
changeTextLength();